import React, { useState, useEffect } from "react";
import { union, groupBy, sortBy } from "lodash";
import { graphql } from "gatsby";
import {
  Switzerland,
  Germany,
  Austria,
  USA,
  Italy,
  getStateLoc,
} from "../data/data";
import DefaultLayout from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import GreenLead from "../components/GreenLead";
import HerstellerUebersicht from "../components/HerstellerUebersicht";
import MapBox from "../components/MapBox";
import WhiteLead from "../components/WhiteLead";

interface HerstellerUebersichtProps {
  data: {
    cms: {
      entry: {
        title: string;
        greenLead: string;
        whiteLead: string;
        children: [
          {
            title: string;
            uri: string;
            subtitle: string;
            strasseHausnummer: string;
            postleitzahl: string;
            ort: string;
            land: string;
            telefon: string;
            latitude: number;
            longitude: number;
          }
        ];
      };
    };
  };
}

export const HerstellerUebersichtQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      entry(uri: [$uri], siteId: [$siteId]) {
        siteId
        title
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_main_hersteller_Entry {
          greenLead
          metaBeschreibung
          whiteLead
          children {
            title
            uri
            ... on CMS_main_herstellerItem_Entry {
              strasseHausnummer
              postleitzahl
              ort
              land
              telefon
              latitude
              longitude
              kantonBundeslandState
            }
          }
        }
      }
    }
  }
`;

const countries = {
  ch: Switzerland,
  us: USA,
  at: Austria,
  de: Germany,
  it: Italy,
};

const HerstellerUebersichtPage: React.FC<HerstellerUebersichtProps> = ({
  data,
}) => {
  //document.cookie = "country=COUNTRYVALUE"

  //let cookies = document.cookie (returns string of all cookie names and values seperated by ";")

  //.split that

  const getKantone = (entries) => {
    const Kantone = entries.map((entry) => entry.kantonBundeslandState);
    const KantoneSorted = sortBy(Kantone, (item) => item.toLowerCase());
    return KantoneSorted;
  };

  const countriesWithEntries = groupBy(data.cms.entry.children, "land");

  const [country, setCountry] = useState(Switzerland);
  const [kanton, setKanton] = useState("*");
  const [hersteller, setHersteller] = useState(data.cms.entry.children);
  const [herstellerFilter, setHerstellerFilter] = useState({
    country: country.name,
    kanton: "*",
  });
  const [kantone, setKantone] = useState(union(getKantone(hersteller)));
  const [hoveredHersteller, setHoveredHersteller] = useState("");
  const [stateLoc, setStateLoc] = useState(
    getStateLoc(data.cms.entry.siteId, country)
  );

  useEffect(() => {
    setHerstellerFilter({
      country: country.name,
      kanton: kanton,
    });
    setStateLoc(getStateLoc(data.cms.entry.siteId, country));
  }, [country, kanton]);

  useEffect(() => {
    setKanton("*");
    //change country cookie and wipe kanton cookie
  }, [country]);

  useEffect(() => {
    //change kanton cookie !!!!!!Triggert nicht
    console.log("kanton", kanton);
  }, [kanton]);

  useEffect(() => {
    const newHersteller = data.cms.entry.children
      .filter((entry) => entry.land === herstellerFilter.country)
      .filter((entry) => {
        if (herstellerFilter.kanton === "*") {
          return entry;
        } else {
          return entry.kantonBundeslandState === herstellerFilter.kanton;
        }
      });
    setHersteller(newHersteller);
  }, [herstellerFilter]);

  useEffect(() => {
    setKantone(
      union(
        getKantone(
          data.cms.entry.children.filter(
            (entry) => entry.land === herstellerFilter.country
          )
        )
      )
    );
  }, [hersteller]);

  return (
    <DefaultLayout
      siteId={data.cms.entry.siteId}
      title={data.cms.entry.title}
      //links={data.cms.entry.localized}
      beschreibung={data.cms.entry.metaBeschreibung}
    >
      <HeaderTitle inhalt={data.cms.entry.title} />
      <GreenLead inhalt={data.cms.entry.greenLead} />
      <MapBox
        data={hersteller.map((entry, index) => {
          return {
            index: index,
            latitude: entry.latitude,
            longitude: entry.longitude,
            uri: entry.uri,
            title: entry.title,
          };
        })}
        mapCenter={country}
        hoveredHersteller={hoveredHersteller}
      />
      <HerstellerUebersicht
        siteId={data.cms.entry.siteId}
        inhalt={hersteller}
        kantone={kantone}
        country={country}
        stateLoc={stateLoc}
        countries={countries}
        setCountry={setCountry}
        setKanton={setKanton}
        hoveredHersteller={hoveredHersteller}
        setHoveredHersteller={setHoveredHersteller}
        countriesWithEntries={countriesWithEntries}
      />
      {data.cms.entry.whiteLead ? (
        <WhiteLead inhalt={data.cms.entry.whiteLead} />
      ) : (
        ""
      )}
    </DefaultLayout>
  );
};

export default HerstellerUebersichtPage;
