import React from "react";
import styled from "styled-components";
import { superScriptRHelperHTML } from "../data/superscriptHelper";
import { breakpoints, colors } from "../styles/variables";
import CenteredFourSixthsGrid from "./Layout/Grids/CenteredFourSixthsGrid";
import Wrapper from "./Layout/Wrapper";

interface WhiteLeadProps {
  inhalt: string;
  black?: boolean;
}

const WhiteLeadDiv = styled.div`
  text-align: center;
  background: #fff;

  h2 {
    color: ${colors.green};
    margin-bottom: 6px;
  }

  &.black {
    h2 {
      color: #000;
    }
  }

  p {
    font: 16px / 24px "Oswald Light";

    a[href$=".jpg"],
    a[href$=".jpg"],
    a[href$=".jpg"],
    a[href$=".jpg"],
    a[href$=".jpg"],
    a[href$=".jpeg"],
    a[href$=".tiff"],
    a[href$=".gif"],
    a[href$=".svg"],
    a[href$=".psd"],
    a[href$=".eps"],
    a[href$=".ai"],
    a[href$=".raw"],
    a[href$=".indd"],
    a[href$=".png"] {
      background: url("/assets/file-icons/jpg.svg");
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 25px;
    }

    a[href$=".pptx"],
    a[href$=".pptm"],
    a[href$=".ppt"],
    a[href$=".xps"],
    a[href$=".potx"],
    a[href$=".potm"],
    a[href$=".pot"],
    a[href$=".ppsx"],
    a[href$=".ppsm"],
    a[href$=".pps"],
    a[href$=".odp"],
    a[href$=".doc"],
    a[href$=".docm"],
    a[href$=".dotx"],
    a[href$=".odt"],
    a[href$=".rtf"],
    a[href$=".txt"],
    a[href$=".csv"],
    a[href$=".ods"],
    a[href$=".xlam"],
    a[href$=".xls"],
    a[href$=".xlsb"],
    a[href$=".xlsm"],
    a[href$=".xlsx"],
    a[href$=".xlt"],
    a[href$=".xltx"],
    a[href$=".xlw"],
    a[href$=".xml"],
    a[href$=".xps"] {
      background: url("/assets/file-icons/doc.svg");
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 25px;
    }

    a[href$=".pdf"] {
      background: url("/assets/file-icons/pdf.svg");
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 25px;
    }

    a[href$=".dwg"],
    a[href$=".dxf"],
    a[href$=".stp"],
    a[href$=".cad"],
    a[href$=".stl"],
    a[href$=".dgn"],
    a[href$=".dwt"],
    a[href$=".art"],
    a[href$=".asc"],
    a[href$=".asm"],
    a[href$=".catpart"],
    a[href$=".cf2"],
    a[href$=".dc3"],
    a[href$=".dgn"],
    a[href$=".dst"],
    a[href$=".dwf"],
    a[href$=".dwfx"],
    a[href$=".dwt"],
    a[href$=".dxf"],
    a[href$=".hpgl"],
    a[href$=".ifc"],
    a[href$=".iges"],
    a[href$=".igs"],
    a[href$=".jvsg"],
    a[href$=".nc"],
    a[href$=".nwc"],
    a[href$=".nwd"],
    a[href$=".nwf"],
    a[href$=".pat"],
    a[href$=".phj"],
    a[href$=".plt"],
    a[href$=".psm"],
    a[href$=".pss"],
    a[href$=".rfa"],
    a[href$=".rft"],
    a[href$=".rml"],
    a[href$=".rvt"],
    a[href$=".sldprt"],
    a[href$=".stl"] {
      background: url("/assets/file-icons/cad.svg");
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 25px;
    }

    a[href$=".a"],
    a[href$=".at"],
    a[href$=".iso"],
    a[href$=".tar"],
    a[href$=".bz2"],
    a[href$=".gz"],
    a[href$=".lz"],
    a[href$=".7z"],
    a[href$=".s7z"],
    a[href$=".jar"],
    a[href$=".kgb"],
    a[href$=".rar"],
    a[href$=".tar"],
    a[href$=".tgz"],
    a[href$=".tar.Z"],
    a[href$=".tbz2"],
    a[href$=".lz"],
    a[href$=".tlz"],
    a[href$=".xz"],
    a[href$=".txz"],
    a[href$=".zst"],
    a[href$=".zip"],
    a[href$=".zipx"] {
      background: url("/assets/file-icons/ordner.svg");
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 25px;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    p {
      font: 21px / 31px "Oswald Light";

      a[href$=".jpg"],
      a[href$=".jpg"],
      a[href$=".jpg"],
      a[href$=".jpg"],
      a[href$=".jpg"],
      a[href$=".jpeg"],
      a[href$=".tiff"],
      a[href$=".gif"],
      a[href$=".svg"],
      a[href$=".psd"],
      a[href$=".eps"],
      a[href$=".ai"],
      a[href$=".raw"],
      a[href$=".indd"],
      a[href$=".png"] {
        background: url("/assets/file-icons/jpg.svg");
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 32px;
      }

      a[href$=".pptx"],
      a[href$=".pptm"],
      a[href$=".ppt"],
      a[href$=".xps"],
      a[href$=".potx"],
      a[href$=".potm"],
      a[href$=".pot"],
      a[href$=".ppsx"],
      a[href$=".ppsm"],
      a[href$=".pps"],
      a[href$=".odp"],
      a[href$=".doc"],
      a[href$=".docm"],
      a[href$=".dotx"],
      a[href$=".odt"],
      a[href$=".rtf"],
      a[href$=".txt"],
      a[href$=".csv"],
      a[href$=".ods"],
      a[href$=".xlam"],
      a[href$=".xls"],
      a[href$=".xlsb"],
      a[href$=".xlsm"],
      a[href$=".xlsx"],
      a[href$=".xlt"],
      a[href$=".xltx"],
      a[href$=".xlw"],
      a[href$=".xml"],
      a[href$=".xps"] {
        background: url("/assets/file-icons/doc.svg");
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 32px;
      }

      a[href$=".pdf"] {
        background: url("/assets/file-icons/pdf.svg");
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 32px;
      }

      a[href$=".dwg"],
      a[href$=".dxf"],
      a[href$=".stp"],
      a[href$=".cad"],
      a[href$=".stl"],
      a[href$=".dgn"],
      a[href$=".dwt"],
      a[href$=".art"],
      a[href$=".asc"],
      a[href$=".asm"],
      a[href$=".catpart"],
      a[href$=".cf2"],
      a[href$=".dc3"],
      a[href$=".dgn"],
      a[href$=".dst"],
      a[href$=".dwf"],
      a[href$=".dwfx"],
      a[href$=".dwt"],
      a[href$=".dxf"],
      a[href$=".hpgl"],
      a[href$=".ifc"],
      a[href$=".iges"],
      a[href$=".igs"],
      a[href$=".jvsg"],
      a[href$=".nc"],
      a[href$=".nwc"],
      a[href$=".nwd"],
      a[href$=".nwf"],
      a[href$=".pat"],
      a[href$=".phj"],
      a[href$=".plt"],
      a[href$=".psm"],
      a[href$=".pss"],
      a[href$=".rfa"],
      a[href$=".rft"],
      a[href$=".rml"],
      a[href$=".rvt"],
      a[href$=".sldprt"],
      a[href$=".stl"] {
        background: url("/assets/file-icons/cad.svg");
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 32px;
      }

      a[href$=".a"],
      a[href$=".at"],
      a[href$=".iso"],
      a[href$=".tar"],
      a[href$=".bz2"],
      a[href$=".gz"],
      a[href$=".lz"],
      a[href$=".7z"],
      a[href$=".s7z"],
      a[href$=".jar"],
      a[href$=".kgb"],
      a[href$=".rar"],
      a[href$=".tar"],
      a[href$=".tgz"],
      a[href$=".tar.Z"],
      a[href$=".tbz2"],
      a[href$=".lz"],
      a[href$=".tlz"],
      a[href$=".xz"],
      a[href$=".txz"],
      a[href$=".zst"],
      a[href$=".zip"],
      a[href$=".zipx"] {
        background: url("/assets/file-icons/ordner.svg");
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 32px;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    p {
      margin-bottom: 14px;
      font: 24px / 36px "Oswald Light";

      a[href$=".jpg"],
      a[href$=".jpg"],
      a[href$=".jpg"],
      a[href$=".jpg"],
      a[href$=".jpg"],
      a[href$=".jpeg"],
      a[href$=".tiff"],
      a[href$=".gif"],
      a[href$=".svg"],
      a[href$=".psd"],
      a[href$=".eps"],
      a[href$=".ai"],
      a[href$=".raw"],
      a[href$=".indd"],
      a[href$=".png"] {
        background: url("/assets/file-icons/jpg.svg");
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 36px;
      }

      a[href$=".pptx"],
      a[href$=".pptm"],
      a[href$=".ppt"],
      a[href$=".xps"],
      a[href$=".potx"],
      a[href$=".potm"],
      a[href$=".pot"],
      a[href$=".ppsx"],
      a[href$=".ppsm"],
      a[href$=".pps"],
      a[href$=".odp"],
      a[href$=".doc"],
      a[href$=".docm"],
      a[href$=".dotx"],
      a[href$=".odt"],
      a[href$=".rtf"],
      a[href$=".txt"],
      a[href$=".csv"],
      a[href$=".ods"],
      a[href$=".xlam"],
      a[href$=".xls"],
      a[href$=".xlsb"],
      a[href$=".xlsm"],
      a[href$=".xlsx"],
      a[href$=".xlt"],
      a[href$=".xltx"],
      a[href$=".xlw"],
      a[href$=".xml"],
      a[href$=".xps"] {
        background: url("/assets/file-icons/doc.svg");
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 36px;
      }

      a[href$=".pdf"] {
        background: url("/assets/file-icons/pdf.svg");
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 36px;
      }

      a[href$=".dwg"],
      a[href$=".dxf"],
      a[href$=".stp"],
      a[href$=".cad"],
      a[href$=".stl"],
      a[href$=".dgn"],
      a[href$=".dwt"],
      a[href$=".art"],
      a[href$=".asc"],
      a[href$=".asm"],
      a[href$=".catpart"],
      a[href$=".cf2"],
      a[href$=".dc3"],
      a[href$=".dgn"],
      a[href$=".dst"],
      a[href$=".dwf"],
      a[href$=".dwfx"],
      a[href$=".dwt"],
      a[href$=".dxf"],
      a[href$=".hpgl"],
      a[href$=".ifc"],
      a[href$=".iges"],
      a[href$=".igs"],
      a[href$=".jvsg"],
      a[href$=".nc"],
      a[href$=".nwc"],
      a[href$=".nwd"],
      a[href$=".nwf"],
      a[href$=".pat"],
      a[href$=".phj"],
      a[href$=".plt"],
      a[href$=".psm"],
      a[href$=".pss"],
      a[href$=".rfa"],
      a[href$=".rft"],
      a[href$=".rml"],
      a[href$=".rvt"],
      a[href$=".sldprt"],
      a[href$=".stl"] {
        background: url("/assets/file-icons/cad.svg");
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 36px;
      }

      a[href$=".a"],
      a[href$=".at"],
      a[href$=".iso"],
      a[href$=".tar"],
      a[href$=".bz2"],
      a[href$=".gz"],
      a[href$=".lz"],
      a[href$=".7z"],
      a[href$=".s7z"],
      a[href$=".jar"],
      a[href$=".kgb"],
      a[href$=".rar"],
      a[href$=".tar"],
      a[href$=".tgz"],
      a[href$=".tar.Z"],
      a[href$=".tbz2"],
      a[href$=".lz"],
      a[href$=".tlz"],
      a[href$=".xz"],
      a[href$=".txz"],
      a[href$=".zst"],
      a[href$=".zip"],
      a[href$=".zipx"] {
        background: url("/assets/file-icons/ordner.svg");
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 36px;
      }
    }
  }
`;

const WhiteLead: React.FC<WhiteLeadProps> = ({ inhalt, black }) => (
  <WhiteLeadDiv className={black ? "black" : ""}>
    <Wrapper>
      <CenteredFourSixthsGrid>
        <div
          dangerouslySetInnerHTML={{ __html: superScriptRHelperHTML(inhalt) }}
        ></div>
      </CenteredFourSixthsGrid>
    </Wrapper>
  </WhiteLeadDiv>
);

export default WhiteLead;
