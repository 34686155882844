import React from "react";
import styled from "styled-components";
import HerstellerRow from "./HerstellerRow";

interface HerstellerTableProps {
  inhalt: [
    {
      uri: string;
      title: string;
      subtitle: string;
      ort: string;
      strasseHausnummer: string;
      postleitzahl: string;
      telefon: string;
    }
  ];
  setHoveredHersteller: React.Dispatch<React.SetStateAction<string | number>>;
}

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const HerstellerTable: React.FC<HerstellerTableProps> = ({
  inhalt,
  setHoveredHersteller,
}) => (
  <Table>
    {inhalt.map((hersteller, index) => (
      <HerstellerRow
        key={index}
        index={index}
        setHoveredHersteller={setHoveredHersteller}
        inhalt={hersteller}
      />
    ))}
  </Table>
);

export default HerstellerTable;
