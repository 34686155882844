import React from "react";
import styled from "styled-components";
import HerstellerTable from "./HerstellerUebersicht/HerstellerTable";
import TableHead from "./HerstellerUebersicht/TableHead";
import TableWrapper from "./Layout/TableWrapper";

interface HerstellerUebersichtProps {
  inhalt: {};
  countries: {};
  kantone: [];
  setCountry: React.Dispatch<React.SetStateAction<object>>;
  setKanton: React.Dispatch<React.SetStateAction<string>>;
  siteId: number;
  hoveredHersteller: number | string;
  setHoveredHersteller: React.Dispatch<React.SetStateAction<string | number>>;
  stateLoc: string;
  countriesWithEntries: [];
}

const HerstellerUebersichtDiv = styled.div``;

const HerstellerUebersicht: React.FC<HerstellerUebersichtProps> = ({
  inhalt,
  kantone,
  countries,
  setCountry,
  setKanton,
  siteId,
  hoveredHersteller,
  setHoveredHersteller,
  stateLoc,
  countriesWithEntries,
  country,
}) => {
  return (
    <HerstellerUebersichtDiv>
      <TableHead
        siteId={siteId}
        kantone={kantone}
        countries={countries}
        setCountry={setCountry}
        country={country}
        setKanton={setKanton}
        stateLoc={stateLoc}
        countriesWithEntries={countriesWithEntries}
      />
      <TableWrapper>
        <HerstellerTable
          inhalt={inhalt}
          hoveredHersteller={hoveredHersteller}
          setHoveredHersteller={setHoveredHersteller}
        />
      </TableWrapper>
    </HerstellerUebersichtDiv>
  );
};

export default HerstellerUebersicht;
