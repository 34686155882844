import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { superScriptRHelperHTML } from "../../data/superscriptHelper";
import { breakpoints, colors } from "../../styles/variables";

interface HerstellerRowProps {
  inhalt: {
    uri: string;
    title: string;
    subtitle: string;
    ort: string;
    strasseHausnummer: string;
    postleitzahl: string;
    telefon: string;
  };
  index: number;
  setHoveredHersteller: React.Dispatch<React.SetStateAction<string | number>>;
}
const Row = styled.div`
  padding: 10px 23px;

  .flex {
    /* display: flex;
    flex-direction: row; */
    //justify-content: space-between;
    display: grid;
    max-width: 1360px;
    margin: 0 auto;
    grid-template-columns: 0fr 1fr 0fr;

    > div:nth-of-type(3) {
      text-align: center;
    }
    > div:nth-of-type(2) {
      text-align: center;
    }
  }

  p,
  a,
  span {
    font: 12px /13px "Oswald Light";
  }

  b {
    font-family: "Oswald Regular";
  }

  p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &:hover {
    background: ${colors.green};
    * {
      color: white;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    .flex {
      grid-template-columns: 0.6fr 1.5fr 0.6fr;

      > div:nth-of-type(2),
      > div:nth-of-type(4) {
        display: block;
      }
    }
    padding: 10px 88px;

    p,
    a,
    span {
      font: 14px /17px "Oswald Light";
    }
  }
  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    padding: 12px 112px;
    grid-column-gap: 21px;
    p,
    a,
    span {
      font: 18px /24px "Oswald Light";
    }
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 10px 122px;

    .flex {
      width: 100%;
    }

    p,
    a,
    span {
      font: 21px /28px "Oswald Light";
    }
  }
`;

const HerstellerRow: React.FC<HerstellerRowProps> = ({
  inhalt,
  index,
  setHoveredHersteller,
}) => (
  <Row
    onMouseEnter={() => setHoveredHersteller(index)}
    onMouseLeave={() => setHoveredHersteller("")}
  >
    <Link to={"/" + inhalt.uri}>
      <div className="flex">
        <div></div>
        <div>
          <p>
            <span>
              <b
                dangerouslySetInnerHTML={{
                  __html: superScriptRHelperHTML(inhalt.title),
                }}
              />
            </span>
            <span>
              {inhalt.postleitzahl} {inhalt.ort}
            </span>
          </p>
        </div>
        <div></div>
        <div></div>
      </div>
    </Link>
  </Row>
);

export default HerstellerRow;
