import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Dropdown from "react-kiwi-dropdown";
import { breakpoints } from "../../styles/variables";

interface Country {
  name: string;
  latitude: number;
  longitude: number;
  zoom: number;
  filterTitle: string;
}

interface LandSelectProps {
  countries: {
    ch: Country;
    de: Country;
    at: Country;
    it: Country;
    us: Country;
  };
  setCountry: React.Dispatch<React.SetStateAction<object>>;
  siteId: number;
  countriesWithEntries: {};
}

const StyledDropdown = styled(Dropdown)`
  z-index: 1;
  color: black;

  .KIWI-button {
    color: black;
    background: #e6e6e6;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    border: none;
    background-image: url("/assets/gestaltungselemente/select-arrow-down.svg");
    background-position: 93% center;
    background-repeat: no-repeat;
    background-size: 14.97px 6.41px;
    padding: 3px 7px;
    font: 13px / 18px "Oswald Regular";
    cursor: pointer;
  }

  .KIWI-button-indicator {
    background: green;

    &.selected {
      background: pink;
    }
  }

  .KIWI-single-arrow {
    display: none;
  }

  .KIWI-option-list {
    padding: 0;
    width: calc((100vw - 68px) / 2);
    box-shadow: none;
  }

  .KIWI-option {
    background: #4d4d4d;
    color: white;
    font: 13px / 40px "Oswald Regular";
    padding: 3px 7px;
    cursor: pointer;

    &.selected,
    &:hover {
      background: #e6e6e6;
      color: black;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    .KIWI-option,
    .KIWI-button {
      padding: 11px 7px;
      font: 14px / 18px "Oswald Regular";
    }

    .KIWI-option-list {
      padding: 0;
      width: calc((100vw - 164px) / 4);
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    .KIWI-button {
      background-size: 19.2px 8.23px;
    }
    .KIWI-button,
    .KIWI-option {
      font: 16px / 24px "Oswald Regular";
      padding: 5px 8px;
      width: calc((100vw - 184px) / 4);
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    .KIWI-button {
      background-size: 24px 10.29px;
    }

    .KIWI-option,
    .KIWI-button {
      font: 21px / 32px "Oswald Regular";
      padding: 6px 10px;
      //width: calc((1160px - ((93px + 4.7vw) * 2)) / 4);
      //width: calc((1360px - 184px) / 4);
      width: calc((100vw - 120px - ((93px + 4.7vw) * 2)) / 4);
    }
  }
  @media screen AND (min-width: 1440px) {
    .KIWI-option,
    .KIWI-button {
      //width: calc((1160px - ((93px + 4.7vw) * 2)) / 4);
      width: calc((1360px - 120px) / 4);
    }
  }
`;

//ids: 1: de, 2: en, 3. fr, 4: it

const LandSelect: React.FC<LandSelectProps> = ({
  countries,
  setCountry,
  siteId,
  countriesWithEntries,
}) => {
  const unfilteredOptions = [
    countriesWithEntries.Schweiz
      ? {
          value: countries.ch,
          content:
            siteId === 1
              ? "Schweiz"
              : siteId === 2
              ? "Switzerland"
              : siteId === 3
              ? "Suisse"
              : siteId === 4
              ? "Svizzera"
              : "",
        }
      : null,
    countriesWithEntries.Deutschland
      ? {
          value: countries.de,
          content:
            siteId === 1
              ? "Deutschland"
              : siteId === 2
              ? "Germany"
              : siteId === 3
              ? "Allemagne"
              : siteId === 4
              ? "Germania"
              : "",
        }
      : null,
    countriesWithEntries.Österreich
      ? {
          value: countries.at,
          content:
            siteId === 1
              ? "Österreich"
              : siteId === 2
              ? "Austria"
              : siteId === 3
              ? "Autriche"
              : siteId === 4
              ? "Austria"
              : "",
        }
      : null,
    countriesWithEntries.Italien
      ? {
          value: countries.it,
          content:
            siteId === 1
              ? "Italien"
              : siteId === 2
              ? "Italy"
              : siteId === 3
              ? "Italie"
              : siteId === 4
              ? "Italia"
              : "",
        }
      : null,
    countriesWithEntries.USA
      ? {
          value: countries.us,
          content: "USA",
        }
      : null,
  ];
  const options = unfilteredOptions.filter((item) => item != null);
  const [selectedOption, setSelectedOption] = useState(countries.ch);
  const [selectedOptionName, setSelectedOptionName] = useState(
    siteId === 1
      ? "Schweiz"
      : siteId === 2
      ? "Switzerland"
      : siteId === 3
      ? "Suisse"
      : siteId === 4
      ? "Svizzera"
      : ""
  );

  const onChange = (option) => {
    if (option.value === selectedOption) {
      return;
    } else {
      setSelectedOption(option.value);
      setSelectedOptionName(option.content);
    }
    // close Dropdown on option change
    document.body.click();
  };

  useEffect(() => {
    setCountry(selectedOption);
  }, [selectedOption]);

  return (
    <StyledDropdown
      options={options}
      onChange={onChange}
      buttonLabel={selectedOptionName}
      selectedOption={selectedOption}
    />
  );
};

export default LandSelect;
