import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const WrapperDiv = styled.div`
  padding: 22px 0;
  width: 100%;
  margin: 0 auto;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    padding: 22px 0;
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    padding: 33px 0;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 43px 80px;
  }
`;

const TableWrapper: React.FC = ({ children }) => (
  <WrapperDiv>{children}</WrapperDiv>
);

export default TableWrapper;
